import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, lastValueFrom } from 'rxjs';
import { environment } from 'environment';
import { AuthenticationService } from '../../main/services/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthenticationService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const accessToken = this.authService.accessToken;
        if (accessToken && req.url.startsWith(environment.webApiUrl)) {
            const cloned = req.clone({
                headers: req.headers.set('Authorization', accessToken)
            });

            return next.handle(cloned);
        }
        else {
            return next.handle(req);
        }
    }
}
