import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, lastValueFrom } from 'rxjs';
import { ContentService } from 'services/content.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
	constructor(private contentService: ContentService) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const currentLanguage = this.contentService.currentLanguage !== '' ? this.contentService.currentLanguage : 'en';
		let cloned = req.clone({
			headers: req.headers.set('Customer-Language', currentLanguage)
		});
		return next.handle(cloned);
	}
}
