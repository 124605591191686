import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UspsLanderComponent } from 'components/usps-lander/usps-lander.component';
import { UpsLanderComponent } from 'components/custom/eddm/ups-lander/ups-lander.component';


let routes: Routes = [
	{ path: 'USPSRef.aspx', component: UspsLanderComponent },
	{ path: 'ups', component: UpsLanderComponent },
	{ path: 'InboundRef.aspx', component: UspsLanderComponent },
	{ path: '', loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule) },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		scrollPositionRestoration: "enabled",
		anchorScrolling: 'enabled',
		onSameUrlNavigation: 'reload'
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
